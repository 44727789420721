<template>
  <div>
    <div class="switchBtn">
      <el-button :type="curStyle ? 'primary' : 'info'" :plain="!curStyle" @click=switchBtn(0)>代办</el-button>
      <el-button :type="!curStyle ? 'primary' : 'info'" :plain="curStyle" @click=switchBtn(1)>已办</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%;" max-height="654" border v-loading="loading">
      <el-table-column fixed prop="subject" label="主题" width="170">
      </el-table-column>
      <el-table-column prop="involve" label="涉及公司、部门及具体人员">
      </el-table-column>
      <el-table-column prop="complaint" label="投诉内容" width="260">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="详情" width="260" trigger="hover">
            <div>{{ scope.row.complaint }}</div>
            <span slot="reference">
              {{scope.row.complaint && scope.row.complaint.length > 20? scope.row.complaint.substring(0, 20) + "..." + '' : scope.row.complaint}}
            </span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="contact" label="联系方式">
      </el-table-column>
      <el-table-column prop="companyOrDepartment" label="公司或部门">
      </el-table-column>
      <el-table-column prop="dealStatus" label="处理状态" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.dealStatus === '0' ? '': 'success'">{{scope.row.dealStatus === '0' ? '未处理': '已处理'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="dealResult" label=" 处理结果">
      </el-table-column>
      <el-table-column prop="path" label="附件" width="80">
        <template slot-scope="scope">
          <!--          <a v-if="scope.row.path" :href="downloadUrl" @click.prevent="download(scope.row.path)"
            class="el-icon-download" ref="download">{{scope.row.path.substring(scope.row.path.indexOf('/') + 1).replace(/-副本/, '')}}</a>
          <span v-else>无</span> -->
          <a v-if="scope.row.path" :href="'http://trace.csgpower.com.cn:8089/BMS/fleUploadAndDownload/download?path='+scope.row.path"
            class="el-icon-download">下载</a>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column prop="created" label="日期">
      </el-table-column>
      <el-table-column fixed="right" label="操作" :width="curStyle === true? 200: 100">
        <template slot-scope="scope">
          <el-button @click.native.prevent="changeRow(scope.row, 0)" icon="el-icon-edit" type="text" v-if="curStyle === true">
            处理
          </el-button>
          <el-button @click.native.prevent="changeRow(scope.row, 1)" icon="el-icon-folder-checked" type="text" v-if="curStyle === true">
            提交
          </el-button>
          <el-button @click.native.prevent="changeRow(scope.row, 2)" icon="el-icon-view" type="text">
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="rows"
      layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <el-dialog title="详情页" :visible.sync="dialogVisible" width="40%" top="12vh" @close="handleClose"
      :modal-append-to-body="false" :append-to-body="true" class="dialog">
      <div class="cont-box">
        <el-form :model="curData" ref="curData" :rules="rule" class="list-cont01">
          <el-form-item label="主题:" prop="subject">
            <el-input v-model="curData.subject" type="textarea" size="medium" readonly="readonly"></el-input>
          </el-form-item>
          <el-form-item label="涉及部门及具体人员:" prop="involve">
            <el-input v-model="curData.involve" size="medium" readonly="readonly"></el-input>
          </el-form-item>
          <el-form-item label="投诉内容:" prop="complaint">
            <el-input v-model="curData.complaint" type="textarea" rows="4" size="medium" readonly="readonly"></el-input>
          </el-form-item>
          <el-form-item label="姓名:" prop="name">
            <el-input v-model="curData.name" size="medium" readonly="readonly"></el-input>
          </el-form-item>
          <el-form-item label="联系方式:" prop="contact">
            <el-input v-model="curData.contact" size="medium" readonly="readonly"></el-input>
          </el-form-item>
          <el-form-item label="公司或部门:" prop="companyOrDepartment">
            <el-input v-model="curData.companyOrDepartment" size="medium" readonly="readonly"></el-input>
          </el-form-item>
          <el-form-item label="处理结果:" prop="dealResult">
            <el-input v-model="curData.dealResult" type="textarea" rows="4" size="medium"
              :readonly="curIndex === 0?false:true" placeholder="请填写处理结果"></el-input>
          </el-form-item>
          <el-form-item label="附件:" prop="path">
            <template slot-scope="scope">
              <a v-if="curData.path" :href="axios.defaults.baseURL+'/fleUploadAndDownload/download?path='+curData.path"
                class="el-icon-download">下载</a>
              <span v-else>无</span>
            </template>
            <el-input v-model="curData.path" size="medium" readonly="readonly"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="subCont('curData', 0)" v-if="curIndex === 0">处理</el-button>
        <el-button type="primary" @click="subCont('curData', 1)" v-if="curIndex === 1">提交</el-button>
        <el-button type="info" plain @click="dialogVisible = false" v-if="curIndex === 2">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        dialogVisible: false,
        loading: true,
        page: 1,
        rows: 10,
        total: null,
        tableData: [],
        curData: {},
        rule: {
          dealResult: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }]
        },
        axios: axios,
        curStyle: true,
        dealStatus: '0',
        curIndex: ''
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      switchBtn(index) {
        if (index === 0) {
          this.curStyle = true
          this.dealStatus = '0'
          this.getList()
        } else {
          this.curStyle = false
          this.dealStatus = '1'
          this.getList()
        }
      },
      changeRow(data, index) {
        this.dialogVisible = true
        this.curData = data
        this.curIndex = index
      },
      getList(e) {
        this.loading = true
        if (e === 'search') {
          this.page = 1
        }
        this.$axios.post('/complaints-suggestions/findList', {
          current: this.page,
          size: this.rows,
          dealStatus: this.dealStatus
        }).then(res => {
          this.loading = false
          this.tableData = res.data.data.records
          this.total = res.data.data.total
        }).catch(err => {
          this.loading = false
        })
      },
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.page = val
        this.getList()
      },
      handleClose() {
        
      },
      subCont(formName, index) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = {}
            let tip = ''
            if (index === 0) {
              data = {
                id: this.curData.id,
                dealResult: this.curData.dealResult
              }
              tip = '处理成功'
            } else {
              data = {
                id: this.curData.id,
                dealStatus: '1',
                contact: this.curData.contact,
                name: this.curData.name
              }
              tip = '提交成功'
            }
            this.$axios.post('/complaints-suggestions/save', data).then(res => {
              this.dialogVisible = false
              this.getList()
              this.$message({
                message: tip,
                type: 'success'
              })
            })
          } else {
            return false;
          }
        });
      }
    }
  }
</script>

<style scoped>
  a {
    text-decoration: none;
  }

  .dialog {
    max-height: 100%;
    overflow: hidden;
  }

  .dialog>>>.el-dialog {
    height: 80%;
    box-sizing: border-box;
  }

  .dialog>>>.el-dialog__body {
    height: calc(100% - 200px);
  }

  .cont-box {
    height: 100%;
    overflow-y: auto;
  }

  .switchBtn {
    text-align: center;
    padding: 10px 0 30px;
  }

  .switchBtn .el-button {
    margin: 0;
    padding: 12px 42px;
    border-radius: 0;
  }

  .el-button>>>[class*=el-icon-]+span {
    margin-left: 0 !important;
  }
</style>
